import React from "react";

import IndianMarket from "../assests/IM.svg";
import UsMarket from "../assests/USM.svg";
import Forex from "../assests/Forex.svg";
import Crypto from "../assests/Crypto.svg";

const style = {
  tradingHeading: "text-xl md:text-xl text-white font-sans font-bold",
  tradingDescription: "text-sm text-[#ffffff]/50 font-poppins mt-1",
  flexDiv: "flex flex-row justify-center items-center",
};

const indiaMarket = [
  {
    broker: "Zerodha",
  },
  {
    broker: "Upstox",
  },
  {
    broker: "Angle One",
  },
  {
    broker: "Motilal Oswal",
  },
  {
    broker: "Freetrade",
  },
  {
    broker: "Dhan",
  },
  {
    broker: "Aliceblue",
  },
  {
    broker: "Finvasia",
  },
  {
    broker: "Kotak ",
  },
  {
    broker: "Fyers",
  },
];

const usMarket = [
  {
    broker: "Alpaca",
  },
  {
    broker: "IBKR",
  },
];
const forex = [
  {
    broker: "MT4",
  },
  {
    broker: "MT5",
  },
];
const crypto = [
  {
    broker: "Binance",
  },
  {
    broker: "Kraken",
  },
  {
    broker: "Bitfinex",
  },
  {
    broker: "Bybit",
  },
  {
    broker: "Okx",
  },
  {
    broker: "Huobi",
  },
  {
    broker: "MEXC",
  },
  {
    broker: "Bitstamp",
  },
  {
    broker: "Kucoin",
  },
  {
    broker: "Dydx",
  },
];
const SolutionOne = () => {
  return (
    <div className="px-4 md:px-[28px] lg:px-40 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-16  lg:gap-y-8 lg:gap-x-8 ">
        <div className=" group  duration-150  flex flex-col items-center  space-y-6">
        <div className="flex items-center justify-center w-[200px] h-[200px] ">
         <img src={IndianMarket}  alt="Indian MAarket" className=""  />
         </div>
          <div className="flex flex-col items-center  space-y-6 lg:space-y-4 ml-4 mt-3">
            <div className={style.tradingHeading}>Indian Markets</div>
            <div className="flex items-center justify-center  flex-wrap">
              {indiaMarket.map((ele, i) => {
                return (
                  <div
                    key={i}
                    className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border-[1px] hover:scale-[1.16]  transition-all duration-100 ease-linear cursor-pointer border-[#ffffff]/10 font-poppins rounded-md"
                  >
                    {ele.broker}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

       <div className=" group   ease-linear duration-150  flex flex-col items-center space-y-6">
       <div className="flex items-center justify-center w-[200px] h-[200px] ">

          <img src={UsMarket}   alt="Us MAarket" className="scale-[1.2]" />
       </div>
          <div className="flex flex-col items-center space-y-6 lg:space-y-4 ml-4 mt-3">
            <div className={style.tradingHeading}>US Markets</div>
            <div className="flex items-center justify-center  flex-wrap">
              {usMarket.map((ele, i) => {
                return (
                  <div
                    key={i}
                    className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border-[1px] hover:scale-[1.16]   transition-all duration-100 ease-linear cursor-pointer border-[#ffffff]/10 font-poppins rounded-md"
                  >
                    {ele.broker}
                  </div>
                );
              })}
            </div>
          </div>
        
        </div>

        <div className=" group  ease-linear duration-150  flex flex-col items-center space-y-6">
         <div className="flex items-center justify-center w-[200px] h-[200px] ">
          <img src={Forex}  alt="Forex"  className="scale-[1.2]"  />
         </div>
          <div className="flex flex-col items-center space-y-6 lg:space-y-4 ml-4 mt-3">
            <div className={style.tradingHeading}>Forex</div>
            <div className="flex items-center justify-center  flex-wrap">
              {forex.map((ele, i) => {
                return (
                  <div
                    key={i}
                    className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border-[1px] hover:scale-[1.16]   transition-all duration-100 ease-linear cursor-pointer border-[#ffffff]/10 font-poppins rounded-md"
                  >
                    {ele.broker}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className=" group  ease-linear duration-150  flex flex-col items-center space-y-6">
         <div className="flex items-center justify-center w-[200px] h-[200px]">
          <img src={Crypto}   alt="Crypto"  />
         </div>
          <div className="flex flex-col items-center space-y-6 lg:space-y-4 ml-4 mt-3">
            <div className={style.tradingHeading}>Crypto</div>
            <div className="flex items-center justify-center  flex-wrap">
              {crypto.map((ele, i) => {
                return (
                  <div
                    key={i}
                    className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border-[1px] hover:scale-[1.16]   transition-all duration-100 ease-linear cursor-pointer border-[#ffffff]/10 font-poppins rounded-md"
                  >
                    {ele.broker}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-8">
        <div className="md:flex flex-col hidden">
          <div className={style.tradingHeading}>Indian Markets</div>
          <div className="flex flex-wrap">
            {indiaMarket.map((ele, i) => {
              return (
                <div
                  key={i}
                  className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border border-[#ffffff]/10 font-poppins rounded-2xl"
                >
                  {ele.broker}
                </div>
              );
            })}
          </div>
        </div>
        <div className="ml-12 md:flex flex-col hidden">
          <div className={style.tradingHeading}>US Markets</div>
          <div className="flex flex-row ">
            {usMarket.map((ele, i) => {
              return (
                <div
                  key={i}
                  className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border border-[#ffffff]/10 font-poppins rounded-2xl"
                >
                  {ele.broker}
                </div>
              );
            })}
          </div>
        </div>
        <div className="ml-12 md:flex flex-col hidden">
          <div className={style.tradingHeading}>Forex</div>
          <div className="flex flex-row">
            {forex.map((ele, i) => {
              return (
                <div
                  key={i}
                  className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border border-[#ffffff]/10 font-poppins rounded-2xl"
                >
                  {ele.broker}
                </div>
              );
            })}
          </div>
        </div>
        <div className="ml-4 md:flex flex-col hidden">
          <div className={style.tradingHeading}>Crypto</div>
          <div className="flex flex-wrap">
            {crypto.map((ele, i) => {
              return (
                <div
                  key={i}
                  className="mt-2 mr-2 px-2 py-1 text-sm text-[#ffffff]/50 border border-[#ffffff]/10 font-poppins rounded-2xl"
                >
                  {ele.broker}
                </div>
              );
            })}
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default SolutionOne;
