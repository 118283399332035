import React from "react";
import styles from "./marquee.module.css";

const Marquee = (props) => {
  let { testimonialData } = props;

  return (
    <div className={`${styles.mainMarquee} mt-8`}>
      <div className={styles.wrapper}>
        <div className={`${styles.marquee} flex items-center`}>
          {testimonialData &&
            testimonialData.map((ele, i) => (
              <div
                className={` flex flex-col shadow-lg h-80 w-[350px] border-[1px] border-[#ffffff]/5 bg-gradient-to-b from-[#333333]  to-[#1b1b1b]/0  px-10 py-6 rounded-[20px] m-3`}
                key={`marquee-1-${i + 1}`}
              >
                <div className="min-h-44 text-lg text-[#ffffff]/90 py-4 flex-grow">
                  {ele.content}
                </div>
                <div className="flex flex-row items-center mt-8">
                  <img alt={ele.imageUrl} src={ele.imageUrl} width={50} />
                  <div className="ml-4">
                    <div className="text-white text-base font-light font-poppins">
                      {ele.name}
                    </div>
                    <div className="text-[#ffffff]/50 text-sm font-light font-poppins">
                      {ele.occupation}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className={`${styles.marquee2} flex items-center`}>
          {testimonialData &&
            testimonialData.map((ele, i) => (
              <div
              className={` flex flex-col shadow-lg h-80 w-[350px] border-[1px] border-[#ffffff]/5 bg-gradient-to-b from-[#333333]  to-[#1b1b1b]/0  px-10 py-6 rounded-[20px] m-3`}
                key={`marquee-1-${i + 1}`}
              >
                <div className="min-h-44 text-lg text-[#ffffff]/90 py-4 flex-grow">{ele.content}</div>
                <div className="flex  flex-row items-center mt-8">
                  <img alt={ele.imageUrl} src={ele.imageUrl}  width={50}  />
                  <div className=" ml-4">
                    <div className="text-white text-base font-light font-poppins">
                      {ele.name}
                    </div>
                    <div className="text-[#ffffff]/50 text-sm font-light font-poppins">
                      {ele.occupation}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
