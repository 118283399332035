import React from "react";
import styles from "../marquee/marquee.module.css";
const data = [
  {
    text: "Backtesting",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Forward Testing",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Live Strategies",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Real-time Monitoring",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Advanced Reports",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Research",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Consulting",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  // {
  //   text: "Website (ux/ui)",
  //   desc: "Backtesting evaluates trading strategy performance using historical data.",
  // },
  // {
  //   text: "Mobile Apps",
  //   desc: "Backtesting evaluates trading strategy performance using historical data.",
  // },
   
  {
    text: "System Monitoring ",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Tradingview Customs",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Tech Maintenance",
    desc: "Backtesting evaluates trading strategy performance using historical data.",
  },
  {
    text: "Anthing else ",
    desc: "Eager to venture into new trading opportunities!",
  },
];
const Solution = () => {
  return (
    <div className={`px-4 md:px-20 lg:px-40 mt-12 `}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {data.map((ele, i) => {
          return (
            <div key={i} className={`h-[110px] lg:w-[336px]  rounded-2xl bg-gradient-to-b from-[#ffffff2f] via-[#1b1b1b]/0 to-[#01B0EA]/20  hover:bg-gradient-to-b hover:from-[#FFFFFF4D] hover:via-[#77D5F400]/0 hover:to-[#01B0EA] p-[1px] hover:shadow-2xl hover:shadow-gray-700/20 hover:-translate-y-4 hover:-skew-x-2  hover:scale-105 ease-linear duration-150`}>
              <div
                
                className={` bg-[#1b1b1be1] min-w-full h-full  rounded-2xl  cursor-pointer`}
              >
                <div className="px-1 bg-gradient-to-b from-[#1b1b1b] from-10% via-[#1e1e1e] via-50% to-[#1e1e1e] to-90% hover:bg-gradient-to-b hover:from-[#1b1b1b] from-10% hover:via-[#1b1a1a] via-50% hover:to-[#1b1a1a] to-90%  absolute -mt-4 ml-3 z-10 ">
                  <div className="text-white px-2 text-[19px] leading-[32px] font-sans font-semibold rounded-xl">
                    {ele.text}
                  </div>
                </div>
                <div className="h-full flex items-end  text-[#ffffff]/40 text-[13px] leading-[22px] font-light font-poppins px-6 py-6">
                  {ele.desc}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Solution;
