import React from "react";

import HeroSection from "./components/HeroSection/HeroSection";
import AllPart from "./components/whatSetsApart/AllPart";
import NewFooter from "./components/Footer/NewFooter";

function App() {
  
  return (
    <div>
      <HeroSection />
      <AllPart />
      <NewFooter />
    </div>
  );
}

export default App;
